import type { ProgressHeaderProps } from './types';
import View from './view';
function ProgressHeader({
  title,
  step,
  totalSteps,
  iconSrc,
  animate,
  scrollable,
  fullWidth = false,
  containerMarginTop,
  scrollRef
}: ProgressHeaderProps) {
  return <View title={title} step={step} totalSteps={totalSteps} iconSrc={iconSrc} animate={animate} scrollable={scrollable} fullWidth={fullWidth} containerMarginTop={containerMarginTop} scrollRef={scrollRef} data-sentry-element="View" data-sentry-component="ProgressHeader" data-sentry-source-file="index.tsx" />;
}
export { ProgressHeader, type ProgressHeaderProps };