import { Link } from '@gravity/link';
import Cookies from 'js-cookie';
import type { HasContractsToApproveData } from '@monorepo/interfaces';
import { CookiesKeys } from '@monorepo/interfaces';
import { useRouter } from '@monorepo/router';
import { IsaacLogo } from '@srcTailwindUi/components/IsaacLogo';
import { Menu } from '@srcTailwindUi/components/Menu';
import { cn } from '@srcTailwindUi/lib/utils';
type NavBarProps = {
  className?: string;
  showMenu?: boolean;
  impersonateEnabled?: boolean;
  userName?: string;
  fidcData?: HasContractsToApproveData;
};
function NavBar({
  className,
  showMenu = true,
  impersonateEnabled,
  userName,
  fidcData
}: NavBarProps) {
  const router = useRouter();
  const hasCAIntroCookie = Cookies.get(CookiesKeys.VIEWED_CA_INTRO_PAGE) === 'true';
  return <div className={cn('fixed left-0 top-0 z-50 flex h-14 w-full items-start justify-center bg-blue-600 px-6 py-4', className)} data-sentry-component="NavBar" data-sentry-source-file="index.tsx">
      {!hasCAIntroCookie && <div className="flex w-full max-w-screen-sm justify-between">
          <Link href="#" onClick={() => router.push('/home', {
        useWindowOpen: true
      })} underline="none">
            <IsaacLogo />
          </Link>
          {showMenu && <div className="self-center">
              <Menu impersonateEnabled={impersonateEnabled} userName={userName} fidcData={fidcData} />
            </div>}
        </div>}
    </div>;
}
export { NavBar, type NavBarProps };