'use client';

import Image from 'next/image';
import { useTranslations } from 'next-intl';
import { useRouter } from '@monorepo/router';
import { Button, NavBar } from '@monorepo/tailwind-ui/components';
import error500 from '@src/assets/error500.svg';
export default function NotFound() {
  const t = useTranslations('errorPage.internalServerError');
  const router = useRouter();
  return <div className="mt-14 flex h-screen w-full max-w-screen-sm flex-col items-center justify-center gap-6 bg-white md:mx-auto md:mt-0" data-sentry-component="NotFound" data-sentry-source-file="error.tsx">
      <NavBar showMenu={false} data-sentry-element="NavBar" data-sentry-source-file="error.tsx" />
      <div className="flex w-full max-w-screen-sm flex-col items-center justify-center gap-6 bg-white">
        <Image src={error500.src} alt="Internal Server Error" width={180} height={180} data-sentry-element="Image" data-sentry-source-file="error.tsx" />
        <h1 className="font-roboto text-[28px] font-medium leading-8 text-gray-800">
          {t('title')}
        </h1>
        <span className="text-center text-base text-gray-600">
          {t('description')}
        </span>
      </div>
      <Button variant="primary" onClick={() => router.push('/home', {
      useWindowOpen: true
    })} data-sentry-element="Button" data-sentry-source-file="error.tsx">
        {t('button')}
      </Button>
    </div>;
}