import React from 'react';
import { Button } from '@gravity/button';
import { Heading } from '@gravity/heading';
import { Text } from '@gravity/text';
import type { ErrorProps } from './types';
import ErrorImage from './ErrorImage/index';
export const View = ({
  title,
  subtitle,
  description,
  buttonTitle,
  type,
  onClick,
  fullHeight = true
}: ErrorProps) => {
  return <div className={`flex flex-col items-center justify-center text-center ${fullHeight ? 'h-screen' : 'h-auto'}`} data-sentry-component="View" data-sentry-source-file="view.tsx">
      <ErrorImage type={type} data-sentry-element="ErrorImage" data-sentry-source-file="view.tsx" />
      <Heading variant="heading-h4-regular" className="mb-0 mt-6" data-sentry-element="Heading" data-sentry-source-file="view.tsx">
        {title}
      </Heading>
      <Text variant="body-2-medium" className="m-2 mb-12" data-sentry-element="Text" data-sentry-source-file="view.tsx">
        {subtitle}
      </Text>
      {description && <Text variant="caption-regular" className="mb-6 mt-0 max-w-[447px] text-gray-400">
          {description}
        </Text>}
      <div className={`${type === 'not-found' ? 'w-fit' : 'w-[298px]'}`}>
        <Button fullWidth onClick={onClick} data-testid="error-action-button" data-sentry-element="Button" data-sentry-source-file="view.tsx">
          {buttonTitle}
        </Button>
      </div>
    </div>;
};