import Image from 'next/image';
import type { ErrorType } from '../types';
import ErrorIcon from './assets/errorIcon.svg';
import NotFoundIcon from './assets/notFoundIcon.svg';
type ErrorImageProps = {
  type: ErrorType;
};
const ErrorImage = ({
  type
}: ErrorImageProps) => {
  switch (type) {
    case 'generic':
      return null;
    case 'timeout':
      return <Image src={ErrorIcon.src} alt="error" height={129} width={181} />;
    case 'not-found':
      return <Image src={NotFoundIcon.src} alt="error" height={128} width={179} />;
  }
};
export default ErrorImage;